export const errorStatusMessage = {
  defaultMessage: "L'exécution de la demande a échoué.",
  // auth
  1: 'Utilisateur non autorisé.',
  2: 'E-mail incorrect.',
  3: "Un tel utilisateur n'existe pas",
  4: 'Mot de passe non Corrigé',
  5: "L'utilisateur est banni.",
  6: 'Clé de vérification non valide.',
  7: 'La clé de vérification a expiré.',
  8: "L'utilisateur a déjà réussi la vérification.",
  9: 'Demande de récupération introuvable.',
  10: 'La demande de récupération a expiré.',
  11: 'Demande de récupération introuvable.',
  14: 'Dépassement du nombre de modifications tarifaires par mois',
  15: 'Numéro de téléphone invalide.',
  101: 'Demande de récupération introuvable.',
  102: 'Demande de récupération introuvable.',
  103: 'La transaction ne peut pas être complétée.',
  104: "Erreur lors de l'affichage de l'état de retrait des fonds.",
  105: 'Vous ne pouvez pas retirer de fonds. Les conditions du programme de bonus ne sont pas remplies. Postulez au chat en ligne.',
  106: 'La somme de la sortie est inférieure au minimum.',
  // Site
  201: "Impossible d'obtenir une page plate.",
  202: "Impossible d'obtenir une liste de pages plates.",
  // Money
  501: 'Équilibre insuffisant.',
  502: "L'utilisateur n'existe pas.",
  // bet
  600: 'Le pari ne peut pas être placé.',
  601: "Le pari n'est pas trouvé.",
  602: 'Le pari est déjà acheté.',
  603: "L'événement est terminé.",
  605: "Tu ne peux pas l'être. Équilibre insuffisant",
  606: "Tu ne peux pas l'être. Le pourcentage de bonus n'est pas autorisé.",
  611: "Le pari n'est pas trouvé.",
  612: "L'événement est introuvable.",
  613: "L'événement a déjà commencé.",
  614: 'Les coefs ont été modifiés.',
  615: 'La taille des handicaps a été modifiée.',
  616: "Erreur d'enregistrement+.",
  617: 'Le pari est bloqué par le bookmaker.',
  618: 'Événement déjà arrêté.',
  619: 'Bet amount is more then maximum.',
  620: "Informations sur l'auteur du pari introuvable.",
  621: 'Informations sur le contenu du pari introuvable.',
  622: "Pas d'argent sur le compte du joueur.",
  623: "La réception des paris à l'événement s'est arrêtée.",
  624: 'Meilleur accueil à la variante mais arrêté.',
  625: 'Le pari est vide.',
  626: "Nombre incorrect d'ordinaire.",
  627: "Dans le système express ou, le même événement est utilisé plus d'une fois.",
  628: 'Événements impossibles.',
  629: "La limite de la mise maximale sur une ligne habituelle n'a pas été dépassée.",
  630: "La limite de la mise maximale sur une ligne en direct n'a pas été dépassée.",
  631: "La limite sur le montant de tous les paris placés dans la ligne habituelle n'a pas été dépassée.",
  632: "La limite sur le montant de tous les paris placés dans la ligne en direct n'a pas été dépassée.",
  633: "La limite du paiement maximum sur une ligne habituelle n'a pas été dépassée.",
  634: "La limite du paiement maximum sur une ligne en direct n'a pas été dépassée.",
  635: "La limite de la mise maximale sur une ligne habituelle n'a pas été dépassée.",
  636: "La limite de la mise maximale sur une ligne en direct n'a pas été dépassée.",
  637: "La limite sur le montant de tous les paris placés dans la ligne habituelle n'a pas été dépassée.",
  638: "La limite sur le montant de tous les paris placés dans la ligne en direct n'a pas été dépassée.",
  639: "La limite du paiement maximum sur une ligne habituelle n'a pas été dépassée.",
  640: "La limite du paiement maximum sur une ligne en direct n'a pas été dépassée.",
  641: "La limite de la mise maximale sur une ligne habituelle n'a pas été dépassée.",
  642: "La limite de la mise maximale sur une ligne en direct n'a pas été dépassée.",
  643: "La limite sur le montant de tous les paris placés dans la ligne habituelle n'a pas été dépassée.",
  644: "La limite sur le montant de tous les paris placés dans la ligne en direct n'a pas été dépassée.",
  645: "La limite du paiement maximum sur une ligne habituelle n'a pas été dépassée.",
  646: "La limite du paiement maximum sur une ligne en direct n'a pas été dépassée.",
  647: "La limite de la mise maximale sur une ligne habituelle n'a pas été dépassée.",
  648: "La limite de la mise maximale sur une ligne en direct n'a pas été dépassée.",
  649: "La limite sur le montant de tous les paris placés dans la ligne habituelle n'a pas été dépassée.",
  650: "La limite sur le montant de tous les paris placés dans la ligne en direct n'a pas été dépassée.",
  651: "La limite du paiement maximum sur une ligne habituelle n'a pas été dépassée.",
  652: "La limite du paiement maximum sur une ligne en direct n'a pas été dépassée.",
  653: "La limite de la mise maximale sur une ligne habituelle n'a pas été dépassée.",
  654: "La limite de la mise maximale sur une ligne en direct n'a pas été dépassée.",
  655: "La limite sur le montant de tous les paris placés dans la ligne habituelle n'a pas été dépassée.",
  656: "La limite sur le montant de tous les paris placés dans la ligne en direct n'a pas été dépassée.",
  657: "La limite du paiement maximum sur une ligne habituelle n'a pas été dépassée.",
  658: "La limite du paiement maximum sur une ligne en direct n'a pas été dépassée.",
  659: "La limite du paiement maximum pour le pari en pari sur une ligne habituelle n'a pas été dépassée.",
  660: "La limite du paiement maximum pour le pari dans l'événement de pari dans une ligne en direct n'a pas été dépassée.",
  661: "La limite du paiement maximum pour la mise en événement sur une ligne habituelle n'a pas été dépassée.",
  662: "La limite du paiement maximum pour le pari en événement sur une ligne en direct n'a pas été dépassée.",
  663: "La limite du paiement maximum pour parier en tournoi sur une ligne habituelle n'a pas été dépassée.",
  664: "La limite du paiement maximum pour parier en tournoi sur une ligne en direct n'a pas été dépassée.",
  665: "La limite du paiement maximum pour le pari par utilisateur sur une ligne habituelle n'a pas été dépassée.",
  666: "La limite du paiement maximum pour le pari par utilisateur dans une ligne en direct n'a pas été dépassée.",
  667: "La limite du paiement maximum pour les paris sportifs par utilisateur sur une ligne habituelle n'a pas été dépassée.",
  668: "La limite du paiement maximum pour les paris sportifs par utilisateur sur une ligne en direct n'a pas été dépassée.",
  669: "L'utilisateur n'est pas trouvé.",
  670: "Le point n'est pas trouvé.",
  671: "Tourney n'est pas trouvé.",
  672: "Le contenu du pari n'est pas trouvé.",
  673: "Le pari est en direct, l'événement n'est pas en direct.",
  674: "L'événement est en direct, le pari n'est pas en direct.",
  675: "Le coefficient d'argent est mauvais.",
  236: 'Équilibre insuffisant',
  676: "Un utilisateur a placé plus d'un pari dans une unité de temps.",
  677: 'La mise minimale est supérieure à la mise maximale.',
  678: 'Top-express est introuvable.',
  679: 'Le montant maximum légal de paiement pour le pari a été dépassé.',
  680: "L'événement est dans l'express uniquement.",
  681: "L'événement est dans le pari simple seulement.",
  231: 'Mot de passe trop facile',
  234: "Le nom d'utilisateur est déjà utilisé",
  682: "Mauvais nombre d'ordinaire dans l'express (triple express).",
  683: "Mauvais nombre d'ordinaire dans l'express (quadruple express).",
  684: "Mauvais nombre d'ordinaire dans l'express (quinary express).",
  685: "Mauvais nombre d'ordinaire dans l'express (senary express).",
  686: "Mauvais nombre d'ordinaire dans l'express (express contenant sept paris).",
  687: "Mauvais nombre d'ordinaire dans l'express (express contenant huit paris).",
  688: "Mauvais nombre d'ordinaire dans l'express (express contenant neuf paris).",
  689: "La taille du coef maximum dans l'express a été dépassée.",
  690: 'Le montant de la mise dépasse la limite actuelle du point.',
  691: 'La limite du nombre de ce pari pour cet utilisateur.',
  692: 'Type de logiciel inacceptable, ce logiciel ne peut pas parier.',
  693: 'Monnaie inacceptable.',
  694: 'La limite de placement des paris a été dépassée.',
  999: 'Erreur de Béatroute.',
};
