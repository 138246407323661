import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import { actions } from 'features/users/redux';

import Search from 'components/Search/Search';
import Paginator from 'components/Paginator';
import useSorterByKeys from 'hooks/useSorterByKeys';
import Spinner from 'components/Spinner';

import arrowSvg from '../img/arrow.svg';
import sortSvg from '../img/sort.svg';

import './UsersList.scss';

const b = block('users-list');

const pageCount = 8;

const sortKeys = ['id', 'balance'];

const UsersList = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const { usersList, actionProcessing } = useSelector(
    (state) => state.users,
    shallowEqual
  );
  const { sortedList, handleChangeSort } = useSorterByKeys({
    keys: sortKeys,
    targetArray: usersList?.users ?? [],
  });

  const [searchResult, setSearchResult] = useState('');
  const [page, changePage] = useState(1);
  const pages = Math.ceil(usersList.users?.length / pageCount) || 1;

  useEffect(() => {
    dispatch(actions.getUsersList());
  }, [dispatch]);

  const handleSetSearchResult = useCallback((value) => {
    setSearchResult(value);
  }, []);

  const list = useMemo(() => {
    return (
      sortedList
        ?.filter(
          (t) =>
            ~t.nickname?.toUpperCase().indexOf(searchResult.toUpperCase()) ||
            false
        )
        .slice(pageCount * (page - 1), pageCount * page)
        .map((item) => (
          <div key={item.id} className={b('item')}>
            <span className={b('item-text')}>{item.id}</span>
            <span className={b('item-text')}>{item.nickname}</span>
            <span className={b('item-text')}>{item.balance}</span>
            <Link className={b('arrow')} to={`/user-edit/${item.id}`}>
              <SVGInline svg={arrowSvg} />
            </Link>
          </div>
        )) || []
    );
  }, [searchResult, sortedList, page]);

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('header')}>{locale.userCardBalances}</div>
      <div className={b('search')}>
        <Search onChange={handleSetSearchResult} placeholder={locale.search} />
      </div>
      <div className={b('subheader')}>
        <div className={b('subheader-top')}>
          <span>{locale.userCard}</span>
          <span>{locale.accountBalance}</span>
        </div>
        <div className={b('subheader-bottom')}>
          <span>
            {usersList.quantity} {locale.users}
          </span>
          <span>{usersList.totalBalance}</span>
        </div>
      </div>
      <div className={b('list')}>
        <div className={b('list-header')}>
          <div className={b('list-header-text')}>
            Id
            <SVGInline
              svg={sortSvg}
              onClick={() => handleChangeSort(sortKeys[0])}
              className={b('sort').toString()}
            />
          </div>
          <div className={b('list-header-text')}>{locale.userCard}</div>
          <div className={b('list-header-text')}>
            {locale.accountBalance}
            <SVGInline
              svg={sortSvg}
              onClick={() => handleChangeSort(sortKeys[1])}
              className={b('sort').toString()}
            />
          </div>
        </div>
        {list}
      </div>
      {list.length > 0 && (
        <div className={b('paginator')}>
          <Paginator
            pages={pages}
            currentPage={page}
            locale={locale}
            changePage={changePage}
          />
        </div>
      )}
    </div>
  );
};

export default UsersList;
