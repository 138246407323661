import { addNotify } from 'features/notify';
import { actions as authActions } from 'features/Auth';
import { actions as userSettingsActions } from 'features/userSettings';
import getPasswordSettingLocale from 'shared/helpers/getPasswordSettingLocale';

const actionTypes = {
  ACTION_PROCESSING: 'user/ACTION_PROCESSING',
  CREATE_USER_SUCCESS: 'user/CREATE_USER_SUCCESS',
  GET_USERS_LIST_SUCCESS: 'user/GET_USER_LIST_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'user/RESET_PASSWORD_SUCCESS',
};

function createUser(username, password, callback) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;

    const response = await api.user.createUser({ email: username, password });
    if (response.success) {
      dispatch(addNotify(locale.createSuccess, 'success'));
      dispatch({ type: actionTypes.CREATE_USER_SUCCESS });
      callback && callback();
    } else {
      dispatch(
        addNotify(locale[response.codeStatus] ?? locale.networkError, 'error')
      );
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function getUsersList() {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const response = await api.user.getUsersList();
    if (response.success) {
      dispatch({
        type: actionTypes.GET_USERS_LIST_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch(
        addNotify(locale[response.codeStatus] ?? locale.networkError, 'error')
      );
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function changeBanState(id, value) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.changeBanState(id, value);
    const text = value ? locale.banSuccess : locale.unbanSuccess;
    if (response.success) {
      dispatch(addNotify(text, 'success'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      dispatch(
        addNotify(locale[response.codeStatus] ?? locale.networkError, 'error')
      );
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function resetPassword({ userId, role = 0, newPassword }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const {
      locale: { locale },
      auth: { id: adminId },
      userSettings: { generatedPassword, passwordSetting },
    } = getState();
    const password = newPassword ?? generatedPassword;
    const isPassGeneration = adminId !== userId;
    await dispatch(userSettingsActions.generatePassword(role));
    const response = await api.user.resetPassword(userId, password);
    if (response.success) {
      dispatch(addNotify(locale.resetSuccess, 'success'));
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
      if (!isPassGeneration) dispatch(authActions.logOut());
    } else if (response.codeStatus === 231 && passwordSetting) {
      const setting = passwordSetting.player;
      dispatch(
        addNotify(getPasswordSettingLocale({ setting, locale }), 'error')
      );
    } else {
      dispatch(
        addNotify(locale[response.codeStatus] ?? locale.networkError, 'error')
      );
    }
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

function changeUserBalance(id, amount, isOutcome, callback) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { locale } = getState().locale;
    const response = await api.user.changeUserBalance(id, amount, isOutcome);
    if (response.success) {
      dispatch(getUsersList());
      if (callback) callback(0);
      dispatch(
        addNotify(
          isOutcome ? locale.withdrawalSuccess : locale.topUpSuccess,
          'success'
        )
      );
    } else {
      dispatch(
        addNotify(locale[response.codeStatus] ?? locale.networkError, 'error')
      );
    }
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

export {
  actionTypes,
  createUser,
  getUsersList,
  changeBanState,
  resetPassword,
  changeUserBalance,
};
