import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Changer de langue',
  changePas: 'Changer le mot de passe',
  logout: 'Déconnectez-vous',
  other: 'Autres',
  successAuth: 'Autorisation de réussite',
  notRules: 'Privilèges de connexion insuffisants',
  newPlayer: 'Nouveau joueur',
  username: "Nom d'utilisateur",
  password: 'Mot de Passe',
  create: 'Créer',
  menu: 'Le Menu',
  userCardBalances: 'Solde de la carte utilisateur',
  userCard: "Carte d'utilisateur",
  createdAt: 'Créé à',
  accountBalance: 'Solde du compte',
  parentId: 'ID du PARENT',
  close: 'Fermer',
  nickname: 'Surnom',
  id: 'ID',
  balance: 'Solde',
  banSuccess: "Succès de l'interdiction",
  unbanSuccess: 'Débloquer le succès',
  ban: 'Bannir',
  unban: 'Débannir ',
  banUser: 'Bannir un utilisateur',
  unbanUser: 'Débannir  un utilisateur',
  resetPassword: 'Réinitialiser le mot de passe',
  passwordCopied: 'Le nouveau mot de passe a été copié avec succès',
  save: 'Sauvegarder',
  resetSuccess: 'Succès de la réinitialisation du mot de passe',
  networkError: 'Erreur réseau',
  createSuccess: 'Créer le succès',
  topUp: 'Dépôt',
  page: 'Page Suivante',
  withdrawal: 'Retrait',
  topUpSuccess: 'Succès du dépôt',
  withdrawalSuccess: 'Succès du retrait',
  newPassword: 'Nouveau mot de passe',
  confirmNewPassword: 'Confirmez le nouveau mot de passe',
  display: 'Affichage',
  email: 'E-mail',
  date: 'Date',
  amount: 'Montant',
  login: 'Connexion',
  gameName: 'Nom du jeu',
  name: 'Nom',
  action: 'Actions',
  type: 'Type',
  provider: 'Fournisseur',
  fromUsername: "À partir du nom d'utilisateur",
  toUsername: "Vers le nom d'utilisateur",
  from: 'De',
  to: 'À',
  apply: 'Appliquer',
  userId: "ID D'UTILISATEUR",
  info: 'Info',
  noBets: 'Pas de paris',
  noTransactions: 'Aucune transaction',
  cashRegister: 'Caisse enregistreuse',
  total: 'Total des Dépenses',
  term: 'Terme',
  deposit: 'Dépôt',
  withdraw: 'Retrait',
  payment: 'Paiement',
  information: 'Informations',
  details: 'Détails',
  search: 'Chercher',
  players: 'Joueurs',
  noUsers: 'Aucun utilisateur',
  subusers: 'Sous-utilisateurs',
  users: 'Utilisateurs',
  all: 'Tous',
  noUser: 'Aucun utilisateur',
  passwordRequirements: {
    root: 'Exigences en matière de mot de passe',
    minLength: 'La longueur minimale du mot de passe est',
    digits: 'chiffres',
    letters: 'lettres',
    lowercase: 'lettres en minuscules',
    uppercase: 'lettres en majuscules',
    specialCharacters: 'caractères spéciaux',
  },
  paymentTypes: {
    1: 'Retrait',
    2: 'Dépôt',
  },
  casinoStatuses: {
    1: 'Gagner',
    2: 'Pari',
  },
  createUser: {
    0: 'Nouvel utilisateur',
    1: 'Nouvel utilisateur',
    2: 'Nouveau caissier',
  },
  balances: {
    0: 'Solde de la carte utilisateur',
    1: 'Solde de la carte utilisateur',
    2: 'Solde de la carte de caisse',
  },
  cashiersTransactions: {
    0: 'Transaction utilisateur',
    1: 'Transaction utilisateur',
    2: 'Transaction de caisse',
  },
  bets: {
    0: 'Historique des paris des joueurs',
    1: 'Historique des paris des joueurs',
    2: 'Historique des paris de caisse',
  },
  partners: 'Partners',
  transactionHistory: {
    0: 'Historique des transactions',
    1: 'Historique des transactions',
    2: 'Historique des transactions',
  },

  tree: {
    0: 'Arbre',
    1: 'Arbre',
    2: 'Arbre',
  },
  roles: {
    0: 'Joueur',
    1: 'Caissier',
    2: 'Administrateur',
  },
  role: 'Rôle',
};
